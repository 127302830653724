<template>
  <div>
    <iframe class="box" v-if="src" v-bind="{ src }" width="100%" height="850" />
    <div class="box has-background-cap-grey" v-else>
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <img
            :src="require('core/img/logos/misc/cap.png')"
            alt="CAP HPI"
            width="64px"
            class="is-block"
          />
        </div>
        <div class="column">
          <div v-if="src === null">
            <span class="has-text-white">
              <span>Requesting CAP valuation</span>
              <span class="icon">
                <i class="fal fa-spinner fa-pulse"></i>
              </span>
            </span>
          </div>
          <div v-else-if="src === false || src === ''">
            <span class="has-text-white">
              CAP HPI can't value this vehicle. You will not be charged for this
              valuation.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AutoCheckCap',
  data: () => ({
    src: null
  }),
  async mounted() {
    this.src = await this.getSrc()
  },
  props: {
    capToken: {
      type: String,
      required: true
    }
  },
  computed: mapGetters({
    user: 'auth/user'
  }),
  methods: {
    async getSrc() {
      try {
        const { data } = await this.$node.post('/autocheck-cap', {
          email: this.user.email,
          capToken: this.capToken,
          redirectUri: window.location.href
        })
        this.$mxp.track('autocheck_cap_loaded')
        return data.url
      } catch {
        this.$mxp.track('autocheck_cap_failed')
        return false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.has-background-cap-grey
  background-color: #989898
</style>
